import { VueAutosuggest } from "vue-autosuggest";
import "./vue-autosuggest.scss";

export default {
  components: {
    VueAutosuggest
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
        })
        .sort();
    }
  }
};
