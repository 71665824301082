import { convertNullIfEmpty, isEmpty } from "@/utils/null-check";

class AgencyBranchModel {
    static postPayload(e) {
        return {
            countryId: e.countryId || "",
            stateId: e.stateId || "",
            cityId: e.cityId || "",
            areaId: e.areaId || "",
            address: e.address || "",
            latitude: e.latitude.toString() || "",
            longitude: e.longitude.toString() || "",

            name: e.name || "",
            registerEstateAgentName: e.registerEstateAgentName || "",
            businessRegisterNumber: e.businessRegisterNumber || "",
            email: e.email || "",
            contact: e.contact || "",
            fax: e.fax || "",
            description: e.description || "",
            tenancyAgreementPersonInChargeEmail:
                e.tenancyAgreementPersonInChargeEmail || "",
            sstNumber: e.sstNumber || "",
            ssmNumber: e.ssmNumber || "",
            ejenHartanahNumber: e.ejenHartanahNumber || "",
            branchCode: e.branchCode || ""
        };
    }

    static getToEditResponse(e) {
        return {
            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",

            countryId: e.country.id || "",
            stateId: e.state.id || "",
            cityId: e.city.id || "",
            areaId: e.area.id || "",
            address: convertNullIfEmpty(e.address) || "",
            latitude: !isEmpty(e.latitude) ? parseFloat(e.latitude) : "",
            longitude: !isEmpty(e.longitude) ? parseFloat(e.longitude) : "",

            name: e.name || "",
            registerEstateAgentName: e.registerEstateAgentName || "",
            businessRegisterNumber: e.businessRegisterNumber || "",
            email: e.email || "",
            contact: e.contact || "",
            fax: convertNullIfEmpty(e.fax) || "",
            description: convertNullIfEmpty(e.description) || "",
            tenancyAgreementPersonInChargeEmail:
                e.tenancyAgreementPersonInChargeEmail || "",
            sstNumber: e.sstNumber || "",
            ssmNumber: e.ssmNumber || "",
            ejenHartanahNumber: e.ejenHartanahNumber || "",
            branchCode: e.branchCode || ""
        };
    }
}

export default AgencyBranchModel;
